import React, { useState } from 'react';
import './ClickDigital.css';

function ClickDigital() {
    const [selectedService, setSelectedService] = useState(null);

    const serviceDescriptions = {
        'Développement de sites Web': 'Le développement de sites web inclut la création, la conception et le lancement de sites web sur mesure, adaptés aux besoins des entreprises. Nous utilisons des technologies modernes comme HTML5, CSS3, et JavaScript pour garantir des sites rapides, sécurisés et adaptés aux appareils mobiles.',
        'Développement d\'applications mobiles': 'Nous créons des applications mobiles natives pour iOS et Android, en utilisant des technologies modernes telles que React Native et Flutter. Notre objectif est de fournir des expériences utilisateurs intuitives et performantes, adaptées à vos besoins spécifiques.',
        'Optimisation SEO': 'L\'optimisation pour les moteurs de recherche (SEO) est cruciale pour garantir que votre site soit bien classé dans les résultats des moteurs de recherche. Nous améliorons la visibilité de votre site en optimisant le contenu, la structure, et les backlinks pour attirer un trafic organique de qualité.',
        'Marketing digital': 'Le marketing digital englobe une variété de techniques visant à promouvoir votre entreprise en ligne. Nous utilisons des stratégies telles que le marketing sur les réseaux sociaux, le référencement payant (PPC), l\'emailing et la publicité display pour accroître votre présence en ligne et attirer davantage de clients.',
        'Consultation en stratégie numérique': 'Nous offrons des services de consultation pour vous aider à développer une stratégie numérique efficace. Que vous soyez une startup ou une entreprise établie, nous vous guiderons pour choisir les bonnes technologies et optimiser vos efforts numériques pour obtenir un meilleur retour sur investissement.',
    };

    const handleServiceClick = (service) => {
        setSelectedService(service);
    };

    return (
        <div className="click-digital">
            <h1>Click Digital Solution - Nouvelle Génération</h1>
            <p>
                Fournisseur de services numériques, Click Digital conçoit des solutions technologiques avancées pour améliorer votre présence en ligne. Notre équipe d'experts est dédiée à transformer vos idées en réalité numérique.
            </p>
            <h2>Nos Services</h2>
            <ul className="click-digital-services-list">
                {Object.keys(serviceDescriptions).map((service) => (
                    <li key={service} onClick={() => handleServiceClick(service)}>
                        {service}
                    </li>
                ))}
            </ul>
            {selectedService && (
                <div className="click-digital-service-description">
                    <h3>Description de {selectedService}</h3>
                    <p>{serviceDescriptions[selectedService]}</p>
                </div>
            )}
            <a href="/#services" className="click-digital-back-button">Retour aux entreprises</a>
        </div>
    );
}

export default ClickDigital;
