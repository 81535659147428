import React from 'react'; 
import './Footer.css'; 
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import logo from '../components/img/logoC.png';

function Footer() {
    return (
        <footer className="footer bg-dark text-white py-4">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    {/* Section avec le logo et les droits d'auteur */}
                    <div className="col-md-4 d-flex align-items-center">
                        <img src={logo} alt="Vision Click Logo" className="logo-img mr-2" style={{ width: '50px', height: 'auto' }} />
                        <p className="mb-0">© 2024 Vision Click. Tous Droits Réservés</p>
                    </div>

                    {/* Section avec les liens des pages */}
                    <div className="col-md-4 text-center">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <a href="#services" className="footer-link">Services</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#testimonials" className="footer-link">Testimonials</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#contact" className="footer-link">Contact</a>
                            </li>
                        </ul>
                    </div>

                    {/* Section avec les icônes des réseaux sociaux */}
                    <div className="col-md-4 text-center text-md-right">
                        <div className="social-icons">
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light btn-sm rounded-circle mx-1">
                                <FaFacebookF />
                            </a>
                            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light btn-sm rounded-circle mx-1">
                                <FaTwitter />
                            </a>
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light btn-sm rounded-circle mx-1">
                                <FaInstagram />
                            </a>
                            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light btn-sm rounded-circle mx-1">
                                <FaLinkedinIn />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
