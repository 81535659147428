import React from 'react';
import './Header.css'; 
import logo from '../components/img/logoC.png'; 
import NavigationBar from './NavigationBar';

function Header() {
    return (
        <header className="header">
            <div className="logo">
                <img src={logo} alt="Vision Click Logo" className="logo-img" />
             
            </div>
            <nav>
                <NavigationBar />
            </nav>
        </header>
    );
}

export default Header;
