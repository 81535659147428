import React from 'react';
import './WhyChooseSection.css';

function WhyChooseSection() {
    const features = [
        { icon: 'bi-stars', title: 'Qualité Premium', description: 'Une qualité supérieure garantie.' },
        { icon: 'bi-shield-lock', title: 'Sécurité', description: 'Vos données protégées en tout temps.' },
        { icon: 'bi-lightning', title: 'Rapidité', description: 'Des solutions rapides et efficaces.' },
        { icon: 'bi-people', title: 'Support Client', description: 'Un service client à l’écoute.' },
        { icon: 'bi-globe', title: 'Accessibilité', description: 'Des services disponibles partout.' },
        { icon: 'bi-palette', title: 'Personnalisation', description: 'Adapté à vos besoins spécifiques.' },
    ];

    return (
        <section className="why-choose">
            <h2>Pourquoi choisir Vision Click ?</h2>
            <div className="features-grid">
                {features.map((feature, index) => (
                    <div key={index} className="feature-item">
                        <div className="icon">
                            <i className={`bi ${feature.icon}`}></i>
                        </div>
                        <h4>{feature.title}</h4>
                        <p>{feature.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default WhyChooseSection;
