import React from 'react';
import './ContactSidebar.css';

const ContactSidebar = () => {
  return (
    <div className="contact-sidebar">
      <div className="contact-item">
        <a href="tel:+123456789" className="contact-link">
          <div className="contact-icon">
            <i className="fas fa-phone-alt"></i>
          </div>
          {/* Icône pour téléphone */}
        </a>
      </div>
      <div className="contact-item">
        <a href="mailto:contact@visionclick.com" className="contact-link">
          <div className="contact-icon">
            <i className="fas fa-envelope"></i>
          </div>
          {/* Icône pour email */}
        </a>
      </div>
      <div className="contact-item">
        <a href="/contact" className="contact-link">
          <div className="contact-icon">
            <i className="fas fa-comment-dots"></i>
          </div>
          {/* Icône pour contact */}
        </a>
      </div>
    </div>
  );
}

export default ContactSidebar;
