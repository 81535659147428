import React, { useRef, useState, useEffect } from 'react';
import './CompanyVideoSection.css';
import logo from './img/logoC.png';

function CompanyVideoSection() {
    const videoRef = useRef(null);
    const [volume, setVolume] = useState(0.5);
    const [muted, setMuted] = useState(true);

    const handleVolumeChange = (event) => {
        const newVolume = event.target.value;
        setVolume(newVolume);
        videoRef.current.volume = newVolume;
    };

    const toggleMute = () => {
        setMuted(!muted);
        videoRef.current.muted = !muted;
    };

    useEffect(() => {
        // Forcer la lecture automatique dès que le composant est monté
        const playVideo = () => {
            if (videoRef.current) {
                videoRef.current.play().catch((error) => {
                    console.error('Erreur lors de la lecture automatique de la vidéo:', error);
                });
            }
        };
        playVideo();
    }, []);

    return (
        <section className="video-section">
            <div className="video-container">
                {/* Ajout du logo */}
                <img src={logo} alt="Logo" className="logo" />
                <div className="video-wrapper">
                    <video
                        className="company-video"
                        ref={videoRef}
                        src={require('./video/v1.mp4')}
                        autoPlay
                        loop
                        muted={muted}  // Commence en sourdine pour forcer la lecture automatique
                        playsInline    // Nécessaire pour les appareils mobiles
                    />
                    {/* Contrôle de volume et mute */}
                    <div className="volume-control">
                        <button onClick={toggleMute} className="mute-btn">
                            {muted ? 'Activer le son' : 'Désactiver le son'}
                        </button>
                        <label htmlFor="volume-slider">Volume: </label>
                        <input
                            id="volume-slider"
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={volume}
                            onChange={handleVolumeChange}
                            disabled={muted} // Désactiver si en sourdine
                        />
                    </div>
                </div>

                {/* Overlay texte */}
                <div className="video-overlay">
                    <h2>Découvrez Vision Click</h2>
                    <p>Innovations à portée de clic. Regardez notre histoire et notre mission en vidéo.</p>
                </div>
            </div>
        </section>
    );
}

export default CompanyVideoSection;
