import React from "react";
import { Link } from 'react-router-dom'; // Utilisation de Link pour la navigation React
import "./ServicesSection.css";
import img1 from "../components/img/dc.jpg";
import img2 from "../components/img/ww.jpg";
import img3 from "../components/img/ETT.jpg";
import img4 from "./img/knlg.jpg";
import logo from "../components/img/logoC.png"; 

function ServicesSection() {
    // Fonction de défilement fluide vers la section Services
    const scrollToServices = (event) => {
        event.preventDefault();
        const servicesSection = document.getElementById("services");
        servicesSection.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <section id="services" className="services">
            <div className="animated-title">
                <svg viewBox="0 0 200 10"> 
                    <symbol id="s-text">
                        <text x="50%" y="50%" textAnchor="middle" className="text-line">
                            Nos Entreprises
                        </text>
                    </symbol>
                    <g className="text-group">
                        <use xlinkHref="#s-text" className="text-copy"></use>
                        <use xlinkHref="#s-text" className="text-copy"></use>
                        <use xlinkHref="#s-text" className="text-copy"></use>
                    </g>
                </svg>
                <img src={logo} alt="Logo" className="bouncing-logo" />
            </div>

            <div className="service-cards">
                <div className="service-card" style={{ backgroundImage: `url(${img1})` }}>
                    <Link to="/click-design" className="card-link">
                        <div className="icon">
                            <img src={img1} alt="Click Design Icon" className="small-icon" />
                        </div>
                    </Link>
                    <h3>Click Design</h3>
                    <p>
                        Spécialisé dans l'architecture et la construction, Click Design offre des solutions innovantes et durables pour tous vos projets de construction.
                    </p>
                </div>

                <div className="service-card" style={{ backgroundImage: `url(${img2})` }}>
                    <Link to="/click-digital" className="card-link">
                        <div className="icon">
                            <img src={img2} alt="Click Digital Icon" className="small-icon" />
                        </div>
                    </Link>
                    <h3>Click Digital</h3>
                    <p>
                        Fournisseur de services numériques, Click Digital conçoit des solutions technologiques avancées pour améliorer votre présence en ligne.
                    </p>
                </div>

                <div className="service-card" style={{ backgroundImage: `url(${img3})` }}>
                    <Link to="/click-entertainment" className="card-link">
                        <div className="icon">
                            <img src={img3} alt="Click Entertainment Icon" className="small-icon" />
                        </div>
                    </Link>
                    <h3>Click Entertainment</h3>
                    <p>
                        CLiCK Entertainment se spécialise dans la création visuelle et audiovisuelle, offrant des solutions adaptées aux besoins de ses clients.
                    </p>
                </div>

                <div className="service-card" style={{ backgroundImage: `url(${img4})` }}>
                    <Link to="/click-knowledge" className="card-link">
                        <div className="icon">
                            <img src={img4} alt="Click Knowledge Icon" className="small-icon" />
                        </div>
                    </Link>
                    <h3>Click Knowledge</h3>
                    <p>
                        Outil de suivi 360° de l'étudiant et de son bien-être : CLICK THE LIGHT OF KNOWLEDGE améliore l'éducation avec des solutions numériques et un accompagnement personnalisé pour soutenir le développement académique et personnel des étudiants.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default ServicesSection;
