import React, { useState } from "react";
import "./Contact.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Données soumises : ", formData);
    alert("Votre message a été envoyé avec succès !");
  };

  return (
    <div className="contact-page-wrapper">
      <form className="contact-form" onSubmit={handleSubmit}>
        <h2 className="contact-form-header">Contactez-nous</h2>
        <p className="contact-form-description">
          Nous sommes ravis de vous aider ! Laissez-nous un message, et nous vous répondrons dès que possible.
        </p>
        <div className="contact-form-group">
          <label htmlFor="name">Nom</label>
          <input
            type="text"
            id="name"
            className="contact-input"
            placeholder="Entrez votre nom"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="contact-form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            className="contact-input"
            placeholder="Entrez votre email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="contact-form-group">
          <label htmlFor="phone">Téléphone</label>
          <input
            type="tel"
            id="phone"
            className="contact-input"
            placeholder="Entrez votre numéro de téléphone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="contact-form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            className="contact-textarea"
            placeholder="Votre message..."
            value={formData.message}
            onChange={handleChange}
            rows="4"
          ></textarea>
        </div>
        <button type="submit" className="contact-submit-button">
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
