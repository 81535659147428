const teamData = [
    {
      name: "Yann",
      role: "Développeur Full Stack",
      description:
        "Tout terrain grâce à son expertise, Yann est une force tranquille. Passionné de football, il incarne le sens d'équipe.",
      photo: "/images/yann.jpg",
    },
    {
      name: "Franck",
      role: "Chef de projet",
      description:
        "Visionnaire et méthodique, Franck assure une gestion impeccable des projets. Il adore les challenges ambitieux.",
      photo: "/images/franck.jpg",
    },
    {
      name: "Prince",
      role: "Développeur Frontend",
      description:
        "Créatif et minutieux, Prince conçoit des interfaces qui allient esthétique et fonctionnalité.",
      photo: "/images/prince.jpg",
    },
    {
      name: "Tracy",
      role: "Responsable Marketing",
      description:
        "Avec une approche innovante, Tracy donne vie à des stratégies marketing percutantes.",
      photo: "/images/tracy.jpg",
    },
    // Ajoutez les autres membres de l'équipe ici
  ];
  
  export default teamData;
  