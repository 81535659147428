import React from "react";
import "./BesoinsSection.css"; // Fichier CSS pour les styles

const BesoinsSection = () => {
  const besoins = [
    {
      icon: "bi-briefcase", // Classe Bootstrap pour une icône de "service"
      title: "Stratégie digitale",
      description: "Définissez une stratégie digitale pour propulser votre entreprise.",
    },
    {
      icon: "bi-palette", // Classe Bootstrap pour une icône de "design"
      title: "Design sur mesure",
      description: "Des designs uniques qui reflètent votre identité.",
    },
    {
      icon: "bi-code-slash", // Classe Bootstrap pour une icône de "développement"
      title: "Développement web",
      description: "Créez des sites web performants et optimisés.",
    },
    {
      icon: "bi-graph-up-arrow", // Classe Bootstrap pour une icône de "croissance"
      title: "Optimisation SEO",
      description: "Améliorez votre visibilité sur les moteurs de recherche.",
    },
  ];

  return (
    <section className="besoins">
      <div className="besoins-header">
        <h2 className="besoins-title">Les réponses à vos besoins</h2>
        <p className="besoins-subtitle">
          Découvrez comment nous répondons à vos attentes grâce à nos solutions innovantes.
        </p>
      </div>
      <div className="besoins-cards">
        {besoins.map((besoin, index) => (
          <div className="besoins-card" key={index}>
            <div className="icon">
              <i className={`bi ${besoin.icon}`} aria-hidden="true"></i>
            </div>
            <h3>{besoin.title}</h3>
            <p>{besoin.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BesoinsSection;
