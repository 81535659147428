import React, { useState } from 'react';
import './ClickDesign.css'; 

function ClickDesign() {
    const [selectedService, setSelectedService] = useState(null);

    const serviceDescriptions = {
        'Conception architecturale': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        'Gestion de projet de construction': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sit amet tortor vel orci tincidunt vulputate.',
        'Rénovation et réaménagement': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce egestas eros eu nunc malesuada, a consectetur ligula tincidunt.',
        'Construction de bâtiments résidentiels et commerciaux': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus urna ac ipsum gravida, nec sagittis justo bibendum.',
        'Conseils en développement durable': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate ligula a ligula cursus, id bibendum felis consectetur.',
    };

    const handleServiceClick = (service) => {
        setSelectedService(service);
    };

    return (
        <div className="click-design">
            <h1>Click Design and Construction</h1>
            <p>
                Spécialisé dans l'architecture et la construction, Click Design offre des solutions innovantes et durables pour tous vos projets de construction. Nous mettons un point d'honneur à créer des espaces fonctionnels et esthétiques qui répondent aux besoins de nos clients.
            </p>
            <h2>Nos Services</h2>
            <ul className="click-design-services-list">
                {Object.keys(serviceDescriptions).map((service) => (
                    <li key={service} onClick={() => handleServiceClick(service)}>
                        {service}
                    </li>
                ))}
            </ul>
            {selectedService && (
                <div className="click-design-service-description">
                    <h3>Description de {selectedService}</h3>
                    <p>{serviceDescriptions[selectedService]}</p>
                </div>
            )}
            <a href="/#services" className="click-design-back-button">Retour aux entreprises</a>
        </div>
    );
}

export default ClickDesign;
