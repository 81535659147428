import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HeroSection.css';
import image3 from '../components/img/Ultimate.jpg';


function HeroSection() {
    const [currentImage, setCurrentImage] = useState(0);

    // Tableau d'images importées
    const images = [image3]; //s'il y'a dautres images pour l'intant on en garde qu'une!

    // Cycle les images toutes les 4 secondes
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval); // Nettoie l'intervalle lorsque le composant est démonté
    }, [images.length]);

    return (
        <section
            className="hero"
            style={{ backgroundImage: `url(${images[currentImage]})` }}
        >
            <h1>Présentation de CLICK</h1>
            <p>Nous créons des solutions numériques qui inspirent la croissance.</p>
            <a href="#services" className="btn btn-primary btn-lg">
                Explorez nos Filiales
            </a>
        </section>
    );
}

export default HeroSection;