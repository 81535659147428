import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

const NavigationBar = () => {
    const [scrolled, setScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); // État pour gérer l'ouverture/fermeture du menu

    // Gérer le changement de couleur de la navbar lors du défilement
    const handleScroll = () => {
        const isScrolled = window.scrollY > 50;
        setScrolled(isScrolled);
    };

    // Fonction pour basculer l'état du menu hamburger
    const toggleMenu = () => {
        setMenuOpen(prevMenuOpen => !prevMenuOpen);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Fonction de défilement fluide vers la section "Services"
    const scrollToServices = (event) => {
        event.preventDefault(); // Empêche le comportement par défaut du lien
        const servicesSection = document.getElementById("services");

        // Si on est déjà sur la page d'accueil
        if (window.location.pathname === "/home#service" || window.location.pathname === "home#service") {
            servicesSection.scrollIntoView({ behavior: "smooth" });
        } else {
            // Naviguer vers la page d'accueil et faire défiler vers la section Services
            window.location.href = "/home#services"; // Redirection vers la page d'accueil
            setTimeout(() => {
                servicesSection.scrollIntoView({ behavior: "smooth" });
            }, 500); // Attendre un peu avant de faire défiler la page
        }
    };

    return (
        <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
            <div id="menuToggle" className="custom-menu-toggle">
                <input type="checkbox" checked={menuOpen} onChange={toggleMenu} />
                <span></span>
                <span></span>
                <span></span>
                <ul id="menu" className={`custom-navbar-menu ${menuOpen ? 'open' : ''}`}>
                    <li><Link to="/home">Accueil</Link></li>
                    <li><Link to="/about">À propos</Link></li>
                    <li><a href="#services" onClick={scrollToServices}>Services</a></li> {/* Utilisation de onClick */}
                    <li><Link to="/contact">Contact</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/faq">FAQ</Link></li>
                </ul>
            </div>
        </nav>
    );
};

export default NavigationBar;
