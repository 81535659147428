import React from "react";
import "./QuiSommesNous.css"; 
import logoC from "./img/logoC.png"; 
import teamData from "./data/TeamData"; 

const QuiSommesNous = () => {
  return (
    <section className="qui-sommes-nous">
      {/* Présentation de l'entreprise */}
      <div className="entreprise-presentation">
        <img src={logoC} alt="Logo de l'entreprise" className="entreprise-logo" />
        <div className="entreprise-texte">
          <h2>Qui sommes-nous ?</h2>
          <p>
            Chez <strong>Vision Click</strong>, nous sommes une équipe passionnée et dynamique,
            dédiée à fournir des solutions innovantes et adaptées à vos besoins.
            Notre expertise couvre des domaines variés, garantissant un service 
            complet et de qualité.
          </p>
        </div>
      </div>

      {/* Section équipe */}
      <div className="equipe-section">
        <h3>Notre équipe</h3>
        <div className="equipe-grid">
          {teamData.map((member, index) => (
            <div
              className={`equipe-card ${
                member.name === "Yann" ? "yann" :
                member.name === "Franck" ? "franck" :
                member.name === "Prince" ? "prince" :
                member.name === "Tracy" ? "tracy" : ""
              }`}
              key={index}
            >
              <div className="photo-container">
                <img src={member.photo} alt={member.name} className="equipe-photo" />
              </div>
              <h4 className="equipe-name">{member.name}</h4>
              <p className="equipe-role">{member.role}</p>
              <p className="equipe-description">{member.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default QuiSommesNous;
