import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ServicesSection from './components/ServicesSection';
import TestimonialsSection from './components/TestimonialsSection';
import Footer from './components/Footer';
import CompanyVideoSection from './components/CompanyVideoSection';
import ClickDesign from './components/ClickDesign';
import ClickDigital from './components/ClickDigital';
import Contact from './components/Contact';
import Sidebar from './components/Sidebar';
import BesoinsSection from './components/BesoinsSection';
import QuiSommesNous from './components/QuiSommesNous';
import ContactSidebar from './components/ContactSidebar'; // Importation du nouveau composant

// Composant de la barre de progression
function ProgressBar({ scrollProgress }) {
  return (
    <div className="progress-bar">
      <div className="progress" style={{ width: `${scrollProgress}%` }} />
    </div>
  );
}

function App() {
  const [scrollProgress, setScrollProgress] = useState(0);

  // Calcul de la progression de défilement
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    const scrollPercent = (scrollTop / (scrollHeight - clientHeight)) * 100;
    setScrollProgress(scrollPercent);
  };

  // Ajout de l'écouteur d'événement de défilement
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.title = 'Vision Click';
  }, []);

  return (
    <Router>
      <div className="App">
        {/* Affichage de la barre de progression */}
        <ProgressBar scrollProgress={scrollProgress} />
        
        <Header />
        <Sidebar /> {/* Ajout de la sidebar */}
        <ContactSidebar /> {/* Ajout de la barre de contact à droite */}
        <Routes>
          {/* Routes de la page d'accueil et des sections */}
          <Route path="/" element={<>
            <div id="hero-section"><HeroSection /></div>
            <div id="company-video-section"><CompanyVideoSection /></div>
            <div id="services-section"><ServicesSection /></div>
            <div id="testimonials-section"><TestimonialsSection /></div>
            <div id="besoins-section"><BesoinsSection /></div> {/* Nouvelle section */}
            <div id="nous-section"><QuiSommesNous /></div> {/* Nouvelle section */}
          </>} />
          <Route path="/home" element={<>
            <div id="hero-section"><HeroSection /></div>
            <div id="company-video-section"><CompanyVideoSection /></div>
            <div id="services-section"><ServicesSection /></div>
            <div id="testimonials-section"><TestimonialsSection /></div>
          </>} />
          <Route path="/click-design" element={<ClickDesign />} />
          <Route path="/click-digital" element={<ClickDigital />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
