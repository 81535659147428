import React, { useState } from 'react';

function Sidebar() {
  const sections = [
    { id: 'hero-section', name: 'Présentation de l\'entreprise' },
    { id: 'company-video-section', name: 'Vidéo Présentative de l\'entreprise' },
    { id: 'services-section', name: 'Nos services' },
    { id: 'testimonials-section', name: 'Pourquoi choisir Vision Click ?' },
    { id: 'besoins-section', name: 'Réponses aux besoins' }, 
    { id: 'nous-section', name: 'Qui sommes-nous ?' } 
  ];

  const [hoveredSection, setHoveredSection] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredSection(id);
  };

  const handleMouseLeave = () => {
    setHoveredSection(null);
  };

  const handleClick = (id) => {
    const element = document.getElementById(id);
    window.scrollTo({
      top: element.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <div className="sidebar">
      <ul>
        {sections.map((section) => (
          <li
            key={section.id}
            className="sidebar-item"
            onMouseEnter={() => handleMouseEnter(section.id)}
            onMouseLeave={handleMouseLeave}
          >
            <button
              className="sidebar-link"
              onClick={() => handleClick(section.id)}
            >
              <div className="circle"></div>
              {/* Ajout de la bulle dynamique */}
              {hoveredSection === section.id && (
                <div className="bubble">{section.name}</div>
              )}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
